import { useWhalesyncUser } from '@/hooks/useWhalesyncUser';
import { Button, Modal, Stack, Text } from '@mantine/core';
import Image from 'next/image';
import Link from 'next/link';
import { useSubscriptionStatus } from '../../../../hooks/useSubscriptionStatus';

export const NoSubscriptionDetectedModal = (): JSX.Element => {
  const { isLoading, status, choosePlanUrl } = useSubscriptionStatus();
  const { signOut } = useWhalesyncUser();
  if (isLoading) {
    return <></>;
  }
  return (
    <Modal
      withCloseButton={false}
      size="sm"
      centered
      closeOnClickOutside={false}
      opened={status === 'none'}
      onClose={() => {}}
      title={null}
    >
      <Stack gap="xs" mb="md" align="center">
        <Image src="/assets/logo.svg" width={32} height={32} alt="logo" />
        <Text mt="sm" c="gray.11" fw={500} fz="16px">
          Choose a plan to get started
        </Text>
        <Text ta="center">Build the workflows you need with two-way sync across your most important apps</Text>
      </Stack>
      <Stack gap="xs">
        <Button color="blue" variant="filled" component={Link} href={choosePlanUrl}>
          Choose Plan
        </Button>
        <Button onClick={signOut} variant="outline">
          Switch Accounts
        </Button>
      </Stack>
    </Modal>
  );
};
