import { useWhalesyncUser } from '@/hooks/useWhalesyncUser';
import { FLAGS } from '@/utils/flags-dev';
import useSWR from 'swr';
import { ProductType } from '../lib/client/v1/entities';
import ApiV1 from '../lib/client/v1/methods';
import { Flavor, getBuildFlavor } from '../utils/flags';
import { RouteUrls } from '../utils/route-urls';
import { userLimitDataKey } from '../utils/swr-keys';

export interface SubscriptionState {
  canCreateSync: boolean;
  canActivateSync: boolean;
  canRetrySync: boolean;
  baseCount: number;
  baseLimit: number | null;
  recordCount: number;
  recordLimit: number;
  baseLimitReached: boolean;
  recordLimitReached: boolean;
  status: 'none' | 'dev' | 'valid' | 'expired';
  daysRemaining: number;
  isLoading: boolean;
  planDisplayName: string;
  choosePlanUrl: string;
  resubscribeUrl: string;
  manageSubscriptionUrl: string;
}

const PROD_PICK_PLAN_URL = 'https://www.whalesync.com/pricing';

const OTHER_ENV_PICK_PLAN_URL = RouteUrls.accountSignupWithProductType(`${ProductType.SOLO_1_V90}`);

/*
 Assesses the user's subscription status and returns the permissions for creating, activating, and retrying syncs.
*/
export function useSubscriptionStatus(): SubscriptionState {
  const { user } = useWhalesyncUser();
  const { data: userLimitData, isLoading } = useSWR(userLimitDataKey, async () => ApiV1.getUserLimitData({}), {
    refreshInterval: 5 * 1000,
  });

  const choosePlanUrl = getBuildFlavor() === Flavor.Production ? PROD_PICK_PLAN_URL : OTHER_ENV_PICK_PLAN_URL;
  // See DEV-4961 - For now we are using the same URL for resubscribing as for picking a plan
  const resubscribeUrl = choosePlanUrl;
  const manageSubscriptionUrl = RouteUrls.manageSubscription;
  const baseCount = userLimitData?.baseCount ?? 0;
  const recordCount = userLimitData?.recordCount ?? 0;

  if (FLAGS.SKIP_PAYWALL_FOR_LOCALHOST.get() && getBuildFlavor() === Flavor.Local) {
    // Open permissions for flag and local development environments
    return {
      canCreateSync: true,
      baseLimitReached: false,
      recordLimitReached: false,
      canActivateSync: true,
      canRetrySync: true,
      status: 'dev',
      baseCount,
      baseLimit: null,
      recordCount,
      recordLimit: userLimitData?.recordLimit && userLimitData.recordLimit > 0 ? userLimitData.recordLimit : 1000,
      daysRemaining: 30,
      isLoading,
      choosePlanUrl,
      resubscribeUrl,
      manageSubscriptionUrl,
      planDisplayName: 'Fake Dev Plan',
    };
  }

  const subscription = user?.whalesyncUser?.subscription;
  if (!user?.whalesyncUser || !subscription || !userLimitData) {
    // No subscription detected or we are still loading the limit data
    return {
      canCreateSync: false,
      baseLimitReached: false,
      recordLimitReached: false,
      canActivateSync: false,
      canRetrySync: false,
      status: 'none',
      baseCount: 0,
      baseLimit: 0,
      recordCount: 0,
      recordLimit: 0,
      daysRemaining: 0,
      isLoading,
      choosePlanUrl,
      resubscribeUrl,
      manageSubscriptionUrl,
      planDisplayName: 'No Plan',
    };
  }

  const { baseLimit, recordLimit } = userLimitData;

  if (subscription.status === 'expired') {
    return {
      canCreateSync: false,
      baseLimitReached: false,
      recordLimitReached: false,
      canActivateSync: false,
      canRetrySync: false,
      status: subscription.status,
      baseLimit: 0,
      baseCount,
      recordLimit: 0,
      recordCount,
      daysRemaining: subscription.daysRemaining,
      isLoading,
      choosePlanUrl,
      resubscribeUrl,
      manageSubscriptionUrl,
      planDisplayName: subscription.planDisplayName,
    };
  }

  /* TODO(DEV-4791): Restore base limit restriction */
  return {
    canCreateSync: true, // baseCount < baseLimit,
    baseLimitReached: baseLimit !== null && baseCount >= baseLimit,
    recordLimitReached: recordCount >= recordLimit,
    canActivateSync: true,
    canRetrySync: true,
    status: subscription.status,
    baseLimit,
    baseCount,
    recordLimit,
    recordCount,
    daysRemaining: subscription.daysRemaining,
    isLoading,
    choosePlanUrl,
    resubscribeUrl,
    manageSubscriptionUrl,
    planDisplayName: subscription.planDisplayName,
  };
}
