import { SetupStepIndex, ViewBaseCoreBaseId, ViewBaseMode } from '@/components/contexts/types';
import { UUID } from '@/lib/client/v1/entities';
import { SyncPreviewStep } from '@/routes/sync-preview/types';
import { UrlObject } from 'url';

export class RouteUrls {
  static v1ViewBasePageUrl(query: {
    coreBaseId: ViewBaseCoreBaseId;
    mode: ViewBaseMode;
    step: SetupStepIndex;
  }): UrlObject {
    return { pathname: '/v1/dashboard/bases/[coreBaseId]/[mode]/[step]', query };
  }

  static v1DevComponentPlaygroundUrl = '/v1/dev/component-playground';
  static v1DevSchemaViewerUrl(coreBaseId?: string): UrlObject {
    return { pathname: '/v1/dev/schema-viewer', query: { coreBaseId } };
  }
  static v1DevTokenUrl = '/v1/dev/token';
  static v1IssuesPageUrl = '/v1/issues';
  static v1LogsPageUrl = '/v1/logs';
  static v1DashboardUrl = '/v1/dashboard';
  static v1ManageSubscription = '/v1/account/manage';
  static v1ProfilePageUrl = '/v1/account/profile';
  static v1SignInPageUrl = '/v1/sign-in';
  static v1SignUpPageUrl = '/v1/sign-up';
  static v1LoginPageUrl = '/v1/login';

  // V2 URLs.
  static syncs = '/syncs';
  static newSync = '/syncs/new';
  static issues = '/issues';
  static operations = '/operations';
  static devOptions = '/developer';
  static connections = '/connections';
  static getStarted = '/get-started';
  static settings = '/settings';
  static manageSubscription = '/subscription/manage';
  static profile = '/settings/profile';
  static devTools = '/settings/dev-tools';
  static helpAndSupport = '/help-and-support';
  static accountPayments = '/account/payment';
  static accountSignup = '/account/signup';
  static accountSignupPattern = '/account/signup/(.*)';
  static signIn = '/sign-in';
  static signUp = '/sign-up';

  // Public routes
  static healthPageUrl = '/health';
  static helloApiUrl = '/api/hello';

  // Used in DetailLayout. It does not accept UrlObject

  // Temporarely we do not show the overview so we go to the TM page directly
  static syncRoutePattern = '/syncs/[id]';
  static syncUrl = (coreBaseId: UUID): string => `/syncs/${coreBaseId}`;
  static syncOperations = (coreBaseId: UUID): string => `/syncs/${coreBaseId}/operations`;
  static syncIssues = (coreBaseId: UUID): string => `/syncs/${coreBaseId}/issues`;
  static syncGetStarted = (coreBaseId: UUID): string => `/syncs/${coreBaseId}/setup`;

  static syncPreview = ({
    coreBaseId,
    stepId = SyncPreviewStep.SCAN_RECORDS,
  }: {
    coreBaseId: string;
    stepId?: SyncPreviewStep;
  }): string => `/syncs/${coreBaseId}/sync-preview/${stepId}`;

  static syncEdit = ({ coreBaseId, step }: { coreBaseId: UUID; step?: number }): string =>
    `/syncs/edit/${coreBaseId}` + assembleOptionalQueryString({ step });

  static tableMappings = ({ coreBaseId, isNew }: { coreBaseId: string; isNew?: boolean }): string =>
    `/syncs/${coreBaseId}/table-mappings` +
    assembleOptionalQueryString({ init: isNew ? `${new Date().getTime()}` : undefined });

  static tableMapping = ({
    coreBaseId,
    tableMappingId,
    isNew,
  }: {
    coreBaseId: string;
    tableMappingId: string;
    isNew: boolean;
  }): string =>
    `/syncs/${coreBaseId}/table-mappings/${tableMappingId}/field-mappings` +
    assembleOptionalQueryString({ init: isNew ? `${new Date().getTime()}` : undefined });

  static tableMappingEditor = ({
    coreBaseId,
    tableMappingId,
    isNew,
  }: {
    coreBaseId: string;
    /** falsy converted to 0 to satisfy NextJS path resolvinbg*/
    tableMappingId?: string;
    isNew?: boolean;
  }): string =>
    `/syncs/${coreBaseId}/table-mapping-editor/${Boolean(tableMappingId) ? tableMappingId : '0'}` +
    assembleOptionalQueryString({ init: isNew ? `${new Date().getTime()}` : undefined });

  static issue = (id: string): string => `/issues/${id}`;

  static operation = (id: string): string => `/operations/${id}`;

  static paymentPageWithProductType = (productType: string): string =>
    `${RouteUrls.accountPayments}/${encodeURIComponent(productType)}`;

  static accountSignupWithProductType = (productType: string): string =>
    `${RouteUrls.accountSignup}/${encodeURIComponent(productType)}`;

  static signInPageWithRedirect(redirectUrl: string): string {
    return `${RouteUrls.signIn}?redirect_url=${encodeURIComponent(redirectUrl)}`;
  }

  static signUpPageWithRedirect(redirectUrl: string): string {
    return `${RouteUrls.signUp}?redirect_url=${encodeURIComponent(redirectUrl)}`;
  }

  static issuesForBasePageUrl(coreBaseId: string): string {
    const filters = {
      coreBaseId,
    };

    return `${this.issues}?filters=${encodeURIComponent(JSON.stringify(filters))}`;
  }

  static publicRoutePatterns = [
    RouteUrls.v1SignInPageUrl,
    RouteUrls.v1SignUpPageUrl,
    RouteUrls.v1LoginPageUrl,
    RouteUrls.healthPageUrl,
    RouteUrls.helloApiUrl,
    RouteUrls.signIn,
    RouteUrls.signUp,
    RouteUrls.accountSignupPattern,
    RouteUrls.accountSignup,
  ];

  static subscriptionRoutePatterns = [
    RouteUrls.v1DashboardUrl,
    RouteUrls.v1LogsPageUrl,
    RouteUrls.v1IssuesPageUrl,
    RouteUrls.syncs,
    RouteUrls.issues,
    RouteUrls.operations,
    RouteUrls.connections,
    RouteUrls.getStarted,
  ];

  static isPublicRoute(pathname: string): boolean {
    return RouteUrls.publicRoutePatterns.some((pattern) => new RegExp(pattern).test(pathname));
  }

  static isSubscribedOnlyRoute(pathname: string): boolean {
    return RouteUrls.subscriptionRoutePatterns.some((pattern) => new RegExp(pattern).test(pathname));
  }
}

/**
 * Builds a query string from the paramters provided, filtering out any undefined or null values.
 
 * @param params an object of potential query string parameters
 * @returns a string in ?k=v&k2=v2... format OR an empty string if there are no valid parameters in the object
 */
export function assembleOptionalQueryString(
  params: Record<string, string | number | boolean | undefined | null>,
): string {
  const paramString = Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value as string | number | boolean)}`)
    .join('&');

  return paramString && paramString.length > 0 ? `?${paramString}` : '';
}
