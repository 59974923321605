import { ClerkProvider } from '@clerk/nextjs';
import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import { ClerkAuthContextProvider } from '../components/contexts/clerk-auth';
import { startBugsnag } from '../lib/client/bugsnag';
import { startGTM } from '../lib/client/gtm';
import { PostHogEvents, captureEvent, initializePostHog } from '../lib/client/posthog';

import { SubscriptionContainer } from '@/components/common/subscription-container';
import ErrorBoundary from '@/components/v2/common/ErrorBoundary';
import { MANTINE_THEME as NEW_MANTINE_THEME } from '@/components/v2/ui';

import { useRouter } from 'next/router';
import defaultPosthog from 'posthog-js';

import '../components/v2/ui/styles/global.css';

TimeAgo.addLocale(en);

const BugSnagErrorBoundary = startBugsnag();

const PostHogProviderComponent = initializePostHog();

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter();

  // Google tag manager init
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isGtmActive = startGTM();
  }, []);

  useEffect(() => {
    // Track page views in PostHog
    const handleRouteChange = (url: string): void => {
      captureEvent(PostHogEvents.PAGE_VIEW, { url });
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const mantineThemeProviders = {
    theme: NEW_MANTINE_THEME,
  };

  return (
    <BugSnagErrorBoundary>
      <ColorSchemeScript defaultColorScheme="light" />
      <MantineProvider {...mantineThemeProviders}>
        <ErrorBoundary>
          <Notifications />
          <ClerkProvider>
            <ClerkAuthContextProvider>
              <SubscriptionContainer>
                <PostHogProviderComponent client={defaultPosthog}>
                  <Component {...pageProps} />
                </PostHogProviderComponent>
              </SubscriptionContainer>
            </ClerkAuthContextProvider>
          </ClerkProvider>
        </ErrorBoundary>
        {/* Linter doesn't like jsx or global for some reason */}
        {/* eslint-disable-next-line react/no-unknown-property */}
        <style jsx global>{`
          html,
          body,
          body > div:first-child,
          div#__next,
          div#__next > div {
            min-height: 100vh;
          }
        `}</style>
      </MantineProvider>
    </BugSnagErrorBoundary>
  );
}

export default MyApp;
