import { SVGWrapper, SVGWrapperProps } from '@/components/v2/Icons/SvgWrapper';

export const ArrowCornerDownRight = (props: SVGWrapperProps): JSX.Element => {
  return (
    <SVGWrapper {...props}>
      <path
        d="M2.3335 2.33334V3.15001C2.3335 5.11019 2.3335 6.09029 2.71497 6.83898C3.05053 7.49754 3.58596 8.03298 4.24453 8.36853C4.99322 8.75001 5.97331 8.75001 7.9335 8.75001H11.6668M11.6668 8.75001L8.75016 5.83334M11.6668 8.75001L8.75016 11.6667"
        stroke="#838383"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVGWrapper>
  );
};
