import { useRouter } from 'next/router';
import { ReactNode } from 'react';

import { RouteUrls } from '@/utils/route-urls';
import { NoSubscriptionDetectedModal } from '../v2/common/NoSubscriptionDetectedModal';

export const SubscriptionContainer = ({ children }: { children: ReactNode }): JSX.Element => {
  const { pathname } = useRouter();

  // render subscription modal for pages which requires subscription
  if (RouteUrls.isSubscribedOnlyRoute(pathname)) {
    return (
      <>
        <NoSubscriptionDetectedModal />
        {children}
      </>
    );
  }

  return <>{children}</>;
};
