import { Flavor, getBuildFlavor } from '../../utils/flags';
import { UserInfo } from '../../utils/types';

/** Sets the user information for the intercom widget.
 *  see https://developers.intercom.com/installing-intercom/web/identity-verification/
 */
export function initIntercom(authObject: UserInfo): void {
  const flavor = getBuildFlavor();
  const displayName = authObject.displayName || authObject.email;
  window.Intercom('boot', {
    // These don't need to be secrets based on intercom's docs
    api_base: 'https://api-iam.intercom.io',
    app_id: 'nneqj2ck',
    user_id: authObject.whalesyncUser?.id,
    // Since we will use the same intercom instance for all envs
    // we will add a prefix in the user's name to explicitly show from which env it's coming
    // This only applies to non prod envs.
    name: `${flavor !== Flavor.Production ? `[${flavor}]` : ''}${displayName}`,
    email: authObject.email, // Email address
    user_hash: authObject.whalesyncUser?.intercomHash, // HMAC using SHA-256
    createdAt: authObject.whalesyncUser?.createdAt,
  });
}

/** Closes Intercom's widget. */
export function closeIntercom(): void {
  window.Intercom('shutdown');
}
