import ApiV1 from '@/lib/client/v1/methods';
import { whaleSyncUserKey } from '@/utils/swr-keys';
import { UserInfo } from '@/utils/types';
import { useAuth, useUser } from '@clerk/nextjs';
import { UserResource } from '@clerk/types';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import useSWR from 'swr';

import { User } from '@/lib/client/v1/entities';
import defaultPosthog from 'posthog-js';

interface WhalesyncUser {
  isLoading: boolean;
  user: UserInfo;
  clerkUser: UserResource | null;
  signOut: () => void;
  isImpersonating: boolean;
  isSignedIn?: boolean;
}

/**
 * Posthog tracking to identify user on sign-out
 * @param user
 */
export function trackUserSignOut(user?: User): void {
  if (user) {
    try {
      defaultPosthog.capture('account_user_sign_out', {
        distinct_id: user.id,
        email: user.mostRecentEmail,
      });
    } catch (error) {
      console.log('Error tracking user sign out', error);
    }
  }
  defaultPosthog.reset();
}

export const useWhalesyncUser = (): WhalesyncUser => {
  const { actor, signOut } = useAuth();
  const { user: clerkUser, isLoaded, isSignedIn } = useUser();
  const { data: user, isLoading } = useSWR(whaleSyncUserKey, () => ApiV1.getSignedInUser({}), {
    revalidateOnFocus: true,
    revalidateIfStale: false,
  });

  const router = useRouter();

  const signOutClerk = useCallback(() => {
    /*
     * Clerk handles signouts with an async function and needs special treatment to call it
     */
    if (signOut) {
      const whalesyncUser = user;
      // The Clerk singOut function is async
      const asyncSignOut = async (): Promise<void> => {
        await signOut();
        if (user) {
          trackUserSignOut(whalesyncUser);
        }
      };
      asyncSignOut()
        .catch(console.error)
        .finally(() => router.push('/sign-in'));
    }
  }, [signOut, user]);

  return {
    isLoading: isLoading || !isLoaded,
    user: {
      whalesyncUser: user || null,
      displayName: clerkUser?.fullName || '',
      email: clerkUser?.primaryEmailAddress?.emailAddress || '',
    },
    clerkUser: clerkUser || null,
    signOut: signOutClerk,
    isImpersonating: !!actor,
    isSignedIn,
  };
};
